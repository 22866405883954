<template>
  <div class="d-flex outer-box elevation-12">
    <div class="flex-grow-0 flex-shrink-0 index-box d-flex justify-center align-center" :style="{ background: background }">
      <slot name="key"></slot>
    </div>
    <div class="flex-grow-1 body-box d-flex justify-center flex-column pa-4">
      <slot name="value"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      background: {
        type: String
      }
    }
  }
</script>
<style scoped lang="scss">
  .outer-box {
    border-radius: 64px;
    @media screen and (max-width: 600px) {
      height: 96px;
    }
    @media screen and (min-width: 600px) {
      height: 128px;
    }
  }
  .index-box {
    width: 80px;
    border-radius: 64px 0 0 64px;
  }
  .body-box {
    width: 100%;
    border-radius: 0 64px 64px 0;
  }
</style>