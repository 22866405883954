<template>
  <div>
    <v-container>
      <h1 class="d-flex flex-column align-center justify-center header-1" style="margin: 100px 0 30px 0;">
        <div>{{ $t("5秒前に戻りたいと思わないために。") }}</div>
        <div>{{ $t("メールの「やらかした！」をなくす。") }}</div>
      </h1>
      <h3 class="d-flex flex-column align-center justify-center header-3 primary--text" style="margin: 0px 0 50px 0;">{{ $t("ユーザー数n人以上！") }}</h3>
      <div class="d-flex flex-column align-center" style="position: relative;">
        <v-img src="@/assets/screenshot3.png" eager max-width="800px" width="100%" :aspect-ratio="1920/1542"></v-img>
        <v-img src="@/assets/storyset1.png"
              eager
              width="50%"
              min-width="200px"
              :aspect-ratio="1"
              v-if="$vuetify.breakpoint.xs"></v-img>
        <v-img src="@/assets/storyset1.png"
              eager
              max-width="400px"
              width="30%"
              style="position: absolute;bottom: 0px;left: 0;"
              :aspect-ratio="1"
              v-else></v-img>
      </div>
      <div class="d-flex flex-column align-center my-16">
        <h3 class="header-3 my-4 text-center">{{ $t("インストールリンク") }}</h3>
        <div class="d-flex justify-space-between" style="width: 320px">
          <a :href="$t('Chromeインストールリンク')" target="_blank"><v-img src="@/assets/chrome_icon.png" eager width="64px" class="flex-grow-0" :aspect-ratio="1"></v-img></a>
          <a :href="$t('Firefoxインストールリンク')" target="_blank"><v-img src="@/assets/firefox_icon.png" eager width="64px" class="flex-grow-0" :aspect-ratio="1"></v-img></a>
          <a :href="$t('Edgeインストールリンク')" target="_blank"><v-img src="@/assets/edge_icon.png" eager width="64px" class="flex-grow-0" :aspect-ratio="1"></v-img></a>
        </div>
      </div>
    </v-container>
    <div class="features">
      <v-container>
        <h2 class="d-flex flex-column align-center justify-center header-2 white--text py-12 text-center">
          {{ $t("メール送信前に、From,To,Cc,Bcc,件名,添付ファイルをチェックして、誤送信を防止します。") }}
        </h2>
        <v-row class="mb-12">
          <v-col cols="6" sm="3">
            <v-card height="100px" class="d-flex align-center">
              <v-card-text class="text-center">
                <h3 class="header-3">{{ $t("完全無料") }}</h3>
                <div>{{ $t("どれだけ利用しても") }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="3">
            <v-card height="100px" class="d-flex align-center">
              <v-card-text class="text-center">
                <h3 class="header-3">{{ $t("安心安全") }}</h3>
                <div>{{ $t("利用者情報を守る") }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="3">
            <v-card height="100px" class="d-flex align-center">
              <v-card-text class="text-center">
                <h3 class="header-3">{{ $t("シンプル") }}</h3>
                <div>{{ $t("誰でも簡単") }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="3">
            <v-card height="100px" class="d-flex align-center">
              <v-card-text class="text-center">
                <h3 class="header-3">{{ $t("クロスブラウザ") }}</h3>
                <div>{{ $t("対応") }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="action white--text">
      <v-container class="text-center py-12">
        <v-btn rounded x-large color="primary" width="600" max-width="80%" @click="install">
          <div class="header-3">{{ $t("インストール(完全無料)") }}</div>
        </v-btn>
      </v-container>
    </div>
    <v-container id="features_section">
      <h2 class="d-flex flex-column align-center justify-center header-2 my-16 text-center">
        {{ $t("拡張機能で、シームレスにメールの確認を行います。") }}
      </h2>
      <div class="d-flex mb-12" :class="{'flex-column': $vuetify.breakpoint.xs}">
        <div class="flex-grow-1 py-4" style="width: 100%">
          <div class="grey white--text pa-2 full-rounded text-center mb-8">{{ $t("GMailSend Address Checkなしの場合") }}</div>
          <enumerate-item :background="colors.grey.base" class="mb-6">
            <template v-slot:key>
              <div class="white--text header-2">1</div>
            </template>
            <template v-slot:value>
              <div class="header-3">
                {{ $t("メールを書く") }}
              </div>
            </template>
          </enumerate-item>
          <enumerate-item :background="colors.grey.base" class="mb-6">
            <template v-slot:key>
              <div class="white--text header-2">2</div>
            </template>
            <template v-slot:value>
              <div class="header-3">
                {{ $t("メールを送信") }}
              </div>
            </template>
          </enumerate-item>
          <div class="danger-box elevation-12 d-flex">
            <div style="position: relative;width: 80px">
              <img src="@/assets/storyset2.png" width="160px" style="position: absolute;top: 50%;left: -20px;transform: translateY(-50%);">
            </div>
            <div class="d-flex flex-grow-1 flex-column justify-center text-center pa-4 error--text">
              <div class="header-3">{{ $t("あっ！") }}</div>
              {{ $t("送信先／送信するファイルを間違えた！") }}
            </div>
          </div>
        </div>
        <div class="flex-grow-0 d-flex ma-4 justify-center align-center">
          <v-icon x-large v-if="$vuetify.breakpoint.xs">mdi-arrow-down-circle</v-icon>
          <v-icon x-large v-else>mdi-arrow-right-circle</v-icon>
        </div>
        <div class="flex-grow-1 py-4" style="width: 100%">
          <div class="primary white--text pa-2 full-rounded text-center mb-8">{{ $t("GMailSend Address Checkありの場合") }}</div>
          <enumerate-item :background="$vuetify.theme.themes.light.primary" class="mb-6">
            <template v-slot:key>
              <div class="white--text header-2">1</div>
            </template>
            <template v-slot:value>
              <div class="header-3">
                {{ $t("メールを書く") }}
              </div>
            </template>
          </enumerate-item>
          <enumerate-item :background="$vuetify.theme.themes.light.primary" class="mb-6">
            <template v-slot:key>
              <div class="white--text header-2">2</div>
            </template>
            <template v-slot:value>
              <div class="header-3 primary--text">
                {{ $t("メールを確認") }}
              </div>
              <div :class="{ caption: $vuetify.breakpoint.smAndDown }">
                {{ $t("GMailSend Address Checkerが提示したメール情報のチェックリストを全て確認") }}
              </div>
            </template>
          </enumerate-item>
          <enumerate-item :background="$vuetify.theme.themes.light.primary" class="mb-6">
            <template v-slot:key>
              <div class="white--text header-2">3</div>
            </template>
            <template v-slot:value>
              <div class="header-3">
                {{ $t("安心してメールを送信！") }}
              </div>
            </template>
          </enumerate-item>
        </div>
      </div>
    </v-container>
    <div class="action white--text">
      <v-container class="text-center py-12">
        <v-btn rounded x-large color="primary" width="600" max-width="80%" @click="install">
          <div class="header-3">{{ $t("インストール(完全無料)") }}</div>
        </v-btn>
      </v-container>
    </div>
    <v-container id="usage_section">
      <h2 class="d-flex flex-column align-center justify-center header-2 my-16 text-center">
        {{ $t("誰でも簡単3ステップでメールを確認") }}
      </h2>
      <v-row :class="{'mb-8': $vuetify.breakpoint.smAndDown}">
        <v-col cols="12" md="5" class="d-flex flex-column justify-center py-0">
          <div class="header-3">
            1. {{ $t("インストールすると送信ボタンが確認ボタンに変化します") }}
          </div>
          {{ $t("メールを作成したら，「確認ボタン」を押下します。") }}
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center py-0" :class="{ 'mt-16': $vuetify.breakpoint.mdAndUp }">
          <v-img src="@/assets/screenshot1.png" eager width="100%"
                 :aspect-ratio="1920/1542"
                 :style="{margin: $vuetify.breakpoint.mdAndUp ? '-10% -5%': '0 0'}"></v-img>
        </v-col>
      </v-row>
      <v-row class="flex-row-reverse" :class="{'mb-8': $vuetify.breakpoint.smAndDown}">
        <v-col cols="12" md="5" class="d-flex flex-column justify-center py-0">
          <div class="header-3">
            2. {{ $t("送信前の確認画面で全ての項目を確認してチェックします") }}
          </div>
          {{ $t("From、To、CC、BCC、添付ファイル、これら全ての項目にチェックを入れてOKボタンを押下してダイアログを閉じます") }}
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center py-0">
          <v-img src="@/assets/screenshot3.png" eager width="100%"
                 :aspect-ratio="1920/1542"
                 :style="{margin: $vuetify.breakpoint.mdAndUp ? '-10% -5%': '0 0'}"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" class="d-flex flex-column justify-center py-0">
          <div class="header-3">
            3. {{ $t("チェックが完了したら送信ボタンが復活します") }}
          </div>
          {{ $t("「確認ボタン」が「送信ボタン」に変化するので、「送信ボタン」を押下してメールを送信します") }}
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center py-0 mb-16">
          <v-img src="@/assets/screenshot4.png" eager width="100%"
                 :aspect-ratio="1920/1542"
                 :style="{margin: $vuetify.breakpoint.mdAndUp ? '-10% -5%': '0 0'}"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <div class="action white--text">
      <v-container class="text-center py-12">
        <v-btn rounded x-large color="primary" width="600" max-width="80%" @click="install">
          <div class="header-3">{{ $t("インストール(完全無料)") }}</div>
        </v-btn>
      </v-container>
    </div>
    <v-container class="mb-16" id="donate_section">
      <h2 class="d-flex flex-column align-center justify-center header-2 my-16 text-center">
        {{ $t("以下の方法で開発者に寄付ができます") }}
      </h2>
      <v-row>
        <v-col cols="12" sm="8" order="1" class="d-flex flex-column justify-center my-4">
          <div>{{ $t("金額は15円から選択して寄付することができます。") }}</div>
          <div>{{ $t("贈り主は匿名でも可能です。") }}</div>
          <div>{{ $t("受取人メールアドレスはこちらをご利用ください。") }}</div>
          <div class="d-flex my-4 align-center">
            <v-text-field
              outlined
              readonly
              hide-details
              dense
              prepend-inner-icon="mdi-email"
              :value="receiveEmail"
              ref="receiveEmailDOM"
            ></v-text-field>
            <v-btn depressed rounded class="ml-2" @click="copyEmail">{{ $t("コピー") }}</v-btn>
            <v-snackbar
              v-model="copiedMessageSnack"
              :timeout="2000"
            >
              {{ $t("受取人メールアドレスをコピーしました") }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  @click="copiedMessageSnack = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <v-btn rounded x-large color="primary" width="100%" @click="donate">
            {{ $t("Amazonギフト券で開発者に寄付する") }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" :order="$vuetify.breakpoint.xs? 3: 2" class="d-flex justify-center">
          <v-img src="@/assets/storyset3.png" eager width="100%" :max-width="$vuetify.breakpoint.xs? '200px': '300px'"></v-img>
        </v-col>
        <v-col cols="12" :order="$vuetify.breakpoint.xs? 2: 3" class="d-flex justify-center">
          <v-img src="@/assets/screenshot5.png" eager class="elevation-12 rounded position: relative;"
                 :aspect-ratio="2604/2148">
            <span class="image-text" style="position: absolute;top: 53.8%;left: 42.6%;min-width: 35.1%;">{{ $t("15円から選択可能です") }}</span>
            <span class="image-text" style="position: absolute;top: 64.6%;left: 42.6%;min-width: 21.6%;">{{ receiveEmail }}</span>
            <span class="image-text" style="position: absolute;top: 70.5%;left: 42.6%;min-width: 21.6%;">{{ $t("匿名でOK") }}</span>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container id="release_note_section">
      <h3 class="header-3 my-4 text-center">{{ $t("更新履歴") }}</h3>
      <div class="release-note pa-4 rounded mb-16">
        <div v-for="releaseNote in releaseNotes" :key="releaseNote.version" class="mb-4">
          <div class="font-weight-bold">{{ $t("バージョン") }}: {{ releaseNote.version }}</div>
          <ul>
            <li v-for="item in releaseNote.notes" :key="item">{{ item }}</li>
          </ul>
        </div>
      </div>
    </v-container>
    <div class="action white--text">
      <v-container class="py-12">
          <div class="mb-4">{{ $t("GMailSend Address Checker") }} {{ $t("- 誤送信防止 -") }} ver.{{ latestVersion }}</div>
          <div>SEYA.WORK reserved all rights</div>
          <div class="mb-4"><a href="https://seya.work/" target="_blank">https://seya.work/</a></div>
          <div>Website is designed by <a href="https://gakuseiguild.co.jp" target="_blank">GakuseiGuild</a></div>
      </v-container>
    </div>
  </div>
</template>
<script>
  import colors from 'vuetify/lib/util/colors'
  import EnumerateItem from '@/components/enumerate-item';
  import release_note from '../../release_note.json';
  import utils from '@/utils/utils';
  export default {
    components: {
      EnumerateItem
    },
    data () {
      return {
        colors,
        copiedMessageSnack: false,
        receiveEmail: "giftforseya+gmsck@gmail.com",
        releaseNotes: release_note.reverse() || []
      }
    },
    mounted: function () {
      const hash = location.hash.substring(1);
      setTimeout(() => {
        switch(hash) {
          case "features":
            this.scrollTo("#features_section");
            break;
          case "usage":
            this.scrollTo("#usage_section");
            break;
          case "donate":
            this.scrollTo("#donate_section");
            break;
          case "release_note":
            this.scrollTo("#release_note_section");
            break;
        }
      }, 100);
    },
    computed: {
      latestVersion: function () { return this.releaseNotes.length > 0 ? this.releaseNotes[0].version : null; },
    },
    methods: {
      install: function () {
        const link = this.$t(utils.installURL() + "インストールリンク");
        window.open(link, '_blank');
      },
      donate: function () {
        const link = "https://www.amazon.co.jp/gp/product/B004N3APGO";
        window.open(link, '_blank');
      },
      scrollTo: function (section) {
        this.$scrollTo(section);
      },
      copyEmail: function () {
        const receiveEmailDOM = this.$refs.receiveEmailDOM.$el.querySelector('input');
        receiveEmailDOM.select();
        document.execCommand("copy");

        if (window.getSelection) {window.getSelection().removeAllRanges();}
        else if (document.selection) {document.selection.empty();}

        this.copiedMessageSnack = true;
      }
    }
  }
</script>
<style scoped lang="scss">
  .body {
    color: #625550 !important;
  }
  .features {
    background: var(--v-primary-base);
  }
  .action {
    background: var(--v-secondary-base);
  }
  .danger-box {
    border: solid 2px var(--v-error-base);
    border-radius: 2px;
    @media screen and (max-width: 600px) {
      height: 96px;
    }
    @media screen and (min-width: 600px) {
      height: 128px;
    }
  }
  .image-text {
    color: var(--v-primary-base);
    padding: 0 0.5%;
    transform: translateY(-50%);
    background: #ffffffdd;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: 600px) {
      font-size: 0.4em;
    }
    @media screen and (min-width: 600px) {
      font-size: 1em;
    }
  }
  .release-note {
    height: 400px;
    overflow-y: scroll;
    border: solid 1px var(--v-secondary-lighten4);
  }
</style>
